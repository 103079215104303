<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card style="max-height: 80vh; display: flex; flex-direction: column;">
      <v-card-title class="headline lighten-2 d-flex flex-column pa-0">
        <div class="d-flex flex-row event-title">
          Edit event
          <v-btn icon @click="onCloseDialog"><v-icon>mdi-close</v-icon></v-btn>
        </div>
        <v-divider style="width: 100%;" />
      </v-card-title>
      <div
        class="dialog-content"
        v-if="event.poster._id == profile._id"
        ref="dialogContent"
      >
        <v-card-text class="d-flex flex-row align-center">
          <editable-avatar
            :image="profile.thumbnail ? profile.thumbnail : profile.image"
            :editable="false"
            :size="50"
          />
          <span class="font-20 app-medium-font primary-color pl-3">
            {{
              profile.name
                ? profile.name
                : profile.first_name + " " + profile.last_name
            }}
          </span>
        </v-card-text>
        <v-card-text class="pb-0">
          <v-textarea
            rows="4"
            auto-grow
            label="What do you want to talk about?"
            solo
            flat
            v-model="text"
            ref="textarea"
          />
        </v-card-text>
        <v-hover
          v-slot="{ hover }"
          v-for="(file, index) in selectedFiles"
          :key="index"
        >
          <div class="attachment px-5 pb-5">
            <v-img
              :src="file.url"
              alt="Image"
              class="ma-5"
              v-if="file.type == 'image'"
            />
            <vue-plyr
              ref="plyr"
              :options="{
                autoplay: false,
                muted: true
              }"
              v-else-if="file.type == 'video'"
            >
              <video controls crossorigin playsinline>
                <source :src="file.url" type="video/mp4" />
              </video>
            </vue-plyr>

            <v-btn
              @click="onRemoveAttachment(file)"
              class="remove_button"
              fab
              x-small
              v-show="hover"
            >
              <v-icon v-text="'mdi-close'" size="16" />
            </v-btn>
          </div>
        </v-hover>
        <input
          ref="uploader"
          class="d-none"
          type="file"
          :value="files"
          accept="image/*,video/*"
          @change="onFileChanged"
          multiple
        />
      </div>
      <v-card-actions>
        <v-btn text @click="onMediaPick">
          <div>
            <v-img
              :src="require('@/assets/svg/create-image-event.svg')"
              width="30"
              height="20"
              contain
            />
          </div>
        </v-btn>
        <v-menu
          bottom
          offset-y
          origin="bottom bottom"
          :close-on-content-click="false"
          v-model="emojiKeyboard"
        >
          <template v-slot:activator="{ on: menu, attrs }">
            <v-btn v-bind="attrs" v-on="{ ...menu }" text class="ml-2">
              <v-icon v-text="'mdi-emoticon-happy-outline'" color="#0A5B8A" />
            </v-btn>
          </template>
          <v-card>
            <VEmojiPicker @select="selectEmoji" />
          </v-card>
        </v-menu>
        <v-spacer />
        <v-btn
          color="primary"
          rounded
          @click="onPost"
          :loading="loading"
          :disabled="
            eventText == text && arraysAreIdentical(eventFiles, selectedFiles)
          "
        >
          Save
        </v-btn>
      </v-card-actions>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
import EditableAvatar from "@/components/EditableAvatar.vue";
import Grapheme from "grapheme-splitter";

export default {
  components: { EditableAvatar },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    onCloseDialog: {
      type: Function
    },
    event: {
      type: Object
    },
    onUpdated: {
      type: Function
    }
  },
  data() {
    return {
      loading: false,
      selectedFiles: [],
      files: [],
      emojiKeyboard: false,
      text: "",
      removedFile: false,
      errorMessage: null,
      snackbar: false,
      eventText: "",
      eventFiles: []
    };
  },
  methods: {
    ...mapActions("event", {
      editEvent: "editEvent"
    }),
    onMediaPick() {
      // this.onRemoveAttachment();
      setTimeout(() => {
        window.addEventListener("focus", () => {}, { once: true });
        this.$refs.uploader.click();
      }, 300);
    },
    selectEmoji(emoji) {
      this.insertText(emoji.data);
      this.makeFocus();
    },
    insertAtCursor(original, cursorPos, text) {
      const splitter = new Grapheme();
      const chars = splitter.splitGraphemes(original);

      // Split the original array based on the cursor position.
      let beforeCursor = chars.slice(0, cursorPos);
      let afterCursor = chars.slice(cursorPos);

      // Convert the text to be inserted into an array and get its length.
      const textArray = splitter.splitGraphemes(text);
      let addedLength = textArray.length;

      return {
        newText: [...beforeCursor, ...textArray, ...afterCursor].join(""),
        newCursorPos: cursorPos + addedLength
      };
    },
    insertText(text) {
      const el = this.$refs.textarea.$refs.input;
      let cursorPos = el.selectionEnd;
      const result = this.insertAtCursor(this.text, cursorPos, text);
      this.text = result.newText;
      cursorPos = result.newCursorPos;

      this.$nextTick(() => el.setSelectionRange(cursorPos, cursorPos));
      this.emojiKeyboard = false;
    },
    makeFocus() {
      this.$refs.textarea.$refs.input.focus();
    },
    onFileChanged(e) {
      const newFiles = Array.from(e.target.files).map(file => ({
        type: this.getType(file),
        file: file,
        url: this.getFileMediaUrl(file)
      }));

      // Add the new files to the existing selectedFiles array
      this.selectedFiles = [...this.selectedFiles, ...newFiles];

      this.$nextTick(() => {
        setTimeout(() => {
          this.imageLoading = false;
          this.$forceUpdate();
          const dialogContent = this.$refs.dialogContent;
          if (dialogContent) {
            dialogContent.scrollTop = dialogContent.scrollHeight;
          }
        }, 800);
      });
    },
    onRemoveAttachment(file) {
      const index = this.selectedFiles.indexOf(file);
      if (index !== -1) {
        this.selectedFiles.splice(index, 1);
      }
    },
    onPost() {
      this.loading = true;
      let formData = new FormData();
      let updatedFiles = [];
      for (let i = 0; i < this.selectedFiles.length; i++) {
        if (this.selectedFiles[i].file)
          formData.append("files[]", this.selectedFiles[i].file);
        else updatedFiles.push(this.selectedFiles[i]);
      }
      if (updatedFiles.length)
        formData.append("files", JSON.stringify(updatedFiles));
      if (this.eventText != this.text) formData.append("text", this.text);

      formData.append("_id", this.event._id);

      this.editEvent(formData)
        .then(data => {
          this.loading = false;
          this.selectedFiles = [];
          this.text = "";
          console.log(data);
          this.onUpdated({
            ...data,
            poster: this.event.poster,
            poster_type: this.event.poster_type
          });
        })
        .catch(error => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    getType(file) {
      const mimeType = file.type;

      if (mimeType.startsWith("image/")) {
        return "image";
      } else if (mimeType.startsWith("video/")) {
        return "video";
      } else {
        return "unknown"; // or handle other types as needed
      }
    },
    getFileMediaUrl(file) {
      return URL.createObjectURL(file);
    },
    arraysAreIdentical(arr1, arr2) {
      // Check if the two arrays are of the same length
      if (arr1.length !== arr2.length) return false;

      return (
        arr1.every(obj1 => arr2.some(obj2 => obj1.url === obj2.url)) &&
        arr2.every(obj2 => arr1.some(obj1 => obj1.url === obj2.url))
      );
    }
  },
  computed: {
    ...mapState("auth", {
      profile: "profile"
    })
  },
  watch: {
    initalType(newValue) {
      if (newValue == "media") {
        this.onMediaPick();
      }
    }
  },
  mounted() {
    this.text = this.event.text ? this.event.text : "";
    this.eventText = this.event.text ? this.event.text : "";
    this.eventFiles = this.event.files;
    if (this.event.files) {
      this.selectedFiles = [...this.event.files];
    }
  }
};
</script>
<style scoped>
.attachment {
  overflow: visible;
  position: relative;
  cursor: pointer;
}
.remove_button {
  position: absolute;
  top: 10px;
  right: 30px;
  z-index: 100000;
}

.event-title {
  justify-content: space-between;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 10px;
  padding-right: 24px;
  padding-left: 24px;
}
div :deep(.v-dialog) {
  max-height: 80%;
}
.dialog-content {
  flex-grow: 1;
  overflow-y: auto;
}
div :deep(::-webkit-scrollbar) {
  width: 5px;
}

/* Track */
div :deep(::-webkit-scrollbar-track) {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
div :deep(::-webkit-scrollbar-thumb) {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
div :deep(::-webkit-scrollbar-thumb):hover {
  background: #f0a000;
}
.remove_button {
  top: -13px !important;
}
</style>
