<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay scrollable>
    <v-card class="media-viewer-card">
      <v-btn
        fab
        dark
        absolute
        @click="previous"
        v-if="hasPrevious"
        :style="{ top: '50%', left: '10px', transform: 'translateY(-50%)' }"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <v-btn
        fab
        dark
        absolute
        @click="next"
        v-if="hasNext"
        :style="{ top: '50%', right: '10px', transform: 'translateY(-50%)' }"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>

      <!-- Image Viewer -->
      <div v-if="currentMedia && currentMedia.type === 'image'">
        <v-img
          :src="currentMedia.url"
          alt="Media content"
          class="viewer-image"
          width="100%"
          height="100%"
          contain
        />
      </div>

      <!-- Video Viewer -->
      <div
        v-else-if="currentMedia && currentMedia.type === 'video'"
        class="h-100"
      >
        <vue-plyr :options="{ autoplay: true, muted: false, autopause: true }">
          <video controls crossorigin playsinline id="videoElement">
            <source :src="currentMedia.url" type="video/mp4" />
          </video>
        </vue-plyr>
      </div>

      <!-- Close Button (Top-Right Corner) -->
      <v-btn fab dark absolute style="top:10px; right:10px;" @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    event: {
      type: Object,
      required: true
    },
    startIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      currentIndex: this.startIndex,
      currentMedia: null
    };
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    hasNext() {
      return this.currentIndex < this.event.files.length - 1;
    },
    hasPrevious() {
      return this.currentIndex > 0;
    }
  },
  methods: {
    close() {
      window.removeEventListener("wheel", this.preventScroll);
      this.$emit("input", false);
    },
    next() {
      if (this.hasNext) this.currentIndex++;
      this.currentMedia = this.event.files[this.currentIndex];
    },
    previous() {
      if (this.hasPrevious) this.currentIndex--;
      this.currentMedia = this.event.files[this.currentIndex];
    },
    preventScroll(event) {
      event.preventDefault();
    }
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        // Dialog opened
        window.addEventListener("wheel", this.preventScroll, {
          passive: false
        });
      } else {
        // Dialog closed
        window.removeEventListener("wheel", this.preventScroll);
      }
    }
  },
  mounted() {
    this.currentIndex = this.startIndex;
    this.currentMedia = this.event.files[this.currentIndex];
    if (this.dialog) {
      window.addEventListener("wheel", this.preventScroll, {
        passive: false
      });
    }
  }
};
</script>

<style scoped>
.media-viewer-card {
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  height: 100vh; /* 100% of viewport height */
  display: flex;
  align-items: center;
  justify-content: center;
}

.viewer-image {
  max-height: 90vh; /* Slightly less than the full screen to allow for some margin */
}

.v-dialog__content {
  z-index: 2000001 !important;
}
</style>
